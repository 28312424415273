function fileFormInputLabel(value: HTMLInputElement): void {
    const span: HTMLSpanElement | null = value.parentNode?.querySelector('span') ?? null;

    if (span) {
        value.addEventListener('change', () => {
            span.innerText = value.value.split('\\').pop() || 'Файл не выбран';
            span.classList.remove('hide')
        });
    }
}

export { fileFormInputLabel };
