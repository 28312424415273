import { gql } from '@apollo/client';

export const MAGAZINE_SUBSCRIBE = gql`
    mutation magazineSubscribe($email: String!, $name: String!) {
        subscriptionCreate(input: {email: $email, name: $name, scopes: MAGAZINE}) {
            subscription {
                id
                isSubscribed
            }
            errors {
                code
                field
                message
            }
        }
    }
`;
