import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;

import htmx from 'htmx.org';
window.htmx = htmx;

import Foundation from 'foundation-sites';

jQuery(document).foundation();

import 'htmx-ext-ws';

import 'polyfills/append';
import 'polyfills/forEach';

import 'social-likes-next';
import 'social-likes-next/lib/social-likes_flat.css';

import 'what-input/src/scripts/what-input';

import 'utils/ajax';
import 'utils/debug';
import 'utils/sentry';

import 'components/charCounter';
import 'components/expander';
import 'components/magnificPopup';
import 'components/mobileMenu';

import * as Common from 'common';
import 'styles/default.scss';

interface Elec {
    DaDataApiToken: string;
}

const Elec: Elec = {
    DaDataApiToken: DADATA_API_TOKEN,
};

if (typeof window !== 'undefined') {
    window.Elec = Elec;
}

window.addEventListener('DOMContentLoaded', () => {
    Common.Advert.init('[data-bs]');
    Common.Announce('ul.breadcrumbs', '.grid-announce');
    Common.ControlPanelMenu.init();
    Common.Favorites.init();
    Common.CompanyFollowButton.init();
    Common.UserFollowButton.init();
    Common.UserStopListButton.init();
    Common.InfiniteScroll.init('infinitescroll');
    Common.copyLink('.copy_link');

    document.querySelectorAll('[data-complaint]').forEach(Common.complaintForm);
    document.querySelectorAll('form.search-form').forEach(Common.searchFormValidate);
    document.querySelectorAll('input[type=file]').forEach(Common.fileFormInputLabel);
    document.querySelectorAll('#magazine_subscribe_form').forEach(Common.magazineSubscribeForm);

    if (Foundation.MediaQuery.atLeast('medium')) {
        Common.BackToTop.init();
        Common.companyIconStatus();
    }
});

Common.UserOnlineStatus.init();
