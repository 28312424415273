function searchFormValidate(form: HTMLFormElement): void {
    form.addEventListener('submit', function (e): void {
        const searchInput: HTMLInputElement | null = form.querySelector('input[type=search]');
        const searchValue: string = searchInput?.value.trim() ?? '';
        if (searchValue.length < 3) {
            e.preventDefault();
            alert('Введите не менее 3-х знаков.');
        }
    });
}

export { searchFormValidate };
