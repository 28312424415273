import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CreateAxiosDefaults } from 'axios';

class ApiClientStatic {
    private request: AxiosInstance;

    constructor(baseURL: string) {
        const config: CreateAxiosDefaults = {
            baseURL,
            headers: {
                'Content-type': 'application/json'
            },
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken',
            validateStatus: (status: number) => status < 500,
            maxRedirects: 1
        };

        this.request = axios.create(config);
    }

    public get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.request.get(url, config);
    }

    public delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.request.delete(url, config);
    }

    public head<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.request.head(url, config);
    }

    public options<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.request.head(url, config);
    }

    public post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.request.post(url, data, config);
    }

    public put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.request.post(url, data, config);
    }

    public patch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.request.patch(url, data, config);
    }

    public postForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.request.postForm(url, data, config);
    }

    public putForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.request.putForm(url, data, config);
    }

    public patchForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.request.patchForm(url, data, config);
    }
}

export type ApiClient = ApiClientStatic;
export const ApiClient = ApiClientStatic as typeof ApiClientStatic & (() => ApiClient);

export const apiClient = new ApiClient(API_V1_ENDPOINT);
export default apiClient;
