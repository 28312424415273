import axios, { AxiosError, AxiosResponse } from 'axios';
import Foundation from 'foundation-sites';
import $ from 'jquery';

import './styles/default.scss';

let modalForm: FoundationSites.Reveal;
let modalSuccess: FoundationSites.Reveal;

const handleFormPostSuccess = (response: AxiosResponse<string>): void => {
    if (response.data.toString() === 'OK') {
        modalForm.close();
        modalSuccess.open();
        // $('#complaintModalForm form')[0].reset();
    } else {
        $('#complaintModalForm .form-error').remove();
        $('#complaintModalForm .is-invalid-input').removeClass('is-invalid-input');
        $.each(response.data, (key, val) => {
            $('<span class="form-error is-visible">' + val + '</span>').insertBefore('#id_' + key.toString());
            $('#id_' + key.toString()).addClass('is-invalid-input');
        });
    }
};

const handleFormPostError = (error: AxiosError): void => {
    alert('Во время отправки произошла ошибка. Попробуйте повторить попытку позже.');
    modalForm.close();

    throw new Error(error.message);
};

const handleFormLoadSuccess = (response: AxiosResponse<string>): void => {
    $('body').append(response.data);

    $('#complaintModalForm form').on('submit', function (e) {
        e.preventDefault();

        const url = $(this).attr('action')!;

        axios.post(url, $(this).serialize(), { headers: { 'X-Requested-With': 'XMLHttpRequest' }})
            .then(handleFormPostSuccess)
            .catch(handleFormPostError);
    });

    modalSuccess = new Foundation.Reveal($('#complaintModalSuccess'));
    modalForm = new Foundation.Reveal($('#complaintModalForm'), { closeOnClick: false });
    modalForm.open();
};

const handleFormLoadError = (): void => {
    alert('Произошла ошибка. Попробуйте повторить попытку позже.');
};

export const complaintForm = (elm: HTMLDivElement): void => {
    elm.addEventListener('click', event => {
        event.preventDefault();

        if (!modalForm && elm.dataset.complaintUrl) {
            axios.get(elm.dataset.complaintUrl, { headers: { 'X-Requested-With': 'XMLHttpRequest' }})
                .then(handleFormLoadSuccess)
                .catch(handleFormLoadError);
        } else {
            modalForm.open();
        }
    });
};

export default complaintForm;
