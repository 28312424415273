import $ from 'jquery';

import 'magnific-popup/dist/jquery.magnific-popup';
import 'magnific-popup/src/css/main.scss';

window.addEventListener('DOMContentLoaded', () => {
    $('a[rel*=lightbox]').magnificPopup({
        type: 'image',
        image: {
            tError: 'Не удалось загрузить <a href="%url%">изображение #%curr%</a>. Попробуйте перезагрузить страницу.'
        },
        gallery: {
            enabled: true,
            tPrev: 'Назад',
            tNext: 'Вперед',
            tCounter: '%curr% из %total%',
            navigateByImgClick: true,
            preload: [0, 1]
        },
        tClose: 'Закрыть',
        tLoading: 'Загрузка...'
    });
});
