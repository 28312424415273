import Foundation from 'foundation-sites';

import 'jquery-expander';

const ExpanderConfig: Partial<Expander.Options> = {
    slicePoint: (Foundation.MediaQuery.atLeast('medium')) ? 240 : 175,
    expandText: 'ещё',
    expandEffect: 'fadeIn',
    expandSpeed: 0,
    userCollapse: false
};

export { ExpanderConfig };
