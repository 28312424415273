import axios, { AxiosError, AxiosRequestConfig } from 'axios';

class InfiniteScroll {
    private readonly baseUrl: string;
    private readonly container: HTMLElement;
    private readonly indicator: HTMLElement;
    private isLoading: boolean;
    private currentPage: number;
    private nextPage: number;
    private readonly numPages: number;
    private readonly bottomOffset: number;
    private readonly rewriteHistory: boolean;

    constructor(container: HTMLElement) {
        this.indicator = document.createElement('div');
        this.indicator.setAttribute('id', 'loading');

        this.container = container;
        this.container.appendChild(this.indicator);

        const paginator = document.getElementById('pagination');

        this.baseUrl = container.dataset.baseUrl ?? window.location.href;
        this.currentPage = Number(container.dataset.currentPage ?? 0);
        this.nextPage = Number(container.dataset.nextPage ?? this.currentPage + 1);
        this.numPages = Number(container.dataset.numPages ?? 0);
        this.bottomOffset = Number(container.dataset.bottomOffset ?? 0);
        this.rewriteHistory = (container.dataset.rewriteHistory ?? 'yes') === 'yes';
        this.isLoading = false;

        if (this.currentPage <= 1) {
            if (paginator) {
                paginator.remove();
            }
            window.addEventListener('scroll', this.onScroll);
        }

        if (this.currentPage === 0) {
            window.addEventListener('load', this.onScroll);
        }
    }

    public static init(elementId: string): InfiniteScroll | void {
        const container = document.getElementById(elementId);
        if (container) {
            return new InfiniteScroll(container);
        }
    }

    private onScroll = (): void => {
        const containerRect: DOMRect = this.container.getBoundingClientRect();

        if (this.isLoading) {
            return;
        }

        if (this.currentPage === this.nextPage) {
            return;
        }

        if (this.numPages && this.nextPage > this.numPages) {
            return;
        }

        if (containerRect.bottom > window.innerHeight + this.bottomOffset) {
            return;
        }

        this.currentPage = this.nextPage;

        this.showIndicator();

        const config: AxiosRequestConfig = {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            params: { page: this.nextPage },
            responseType: 'text',
        };

        axios.get(this.baseUrl, config)
            .then(response => {
                this.container.insertAdjacentHTML('beforeend', response.data);

                if (this.rewriteHistory) {
                    history.pushState(response.data, 'Страница ' + this.currentPage, 'page' + this.currentPage + '.html');
                }

                this.nextPage += 1;
            })
            .catch((error: AxiosError) => {
                throw new Error(error.message);
            })
            .finally(this.hideIndicator);
    };

    private showIndicator = (): void => {
        this.isLoading = true;
        this.indicator.style.display = 'block';
    };

    private hideIndicator = (): void => {
        this.isLoading = false;
        this.indicator.style.display = 'none';
    };
}

export { InfiniteScroll };
