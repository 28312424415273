import $ from 'jquery';
import Foundation from 'foundation-sites';

// Mobile menu collapse
((): void => {
    const collapseMob: JQuery<HTMLElement> = $('#collapse-mob');
    const collapseMobButton: JQuery<HTMLElement> = $('#collapse-mob_button');

    if (collapseMob && collapseMobButton) {
        collapseMobButton.addClass('visible');
        collapseMobButton.on('click', (e) => {
            e.preventDefault();
            if (Foundation.MediaQuery.upTo('medium')) {
                collapseMobButton.toggleClass('expanded');
                collapseMob.children().stop().slideToggle('fast');
            }
        });
    }

    if (!Foundation.MediaQuery.atLeast('large')) {
        const collapse: JQuery<HTMLElement> = $('#collapse');
        const collapseButton: JQuery<HTMLElement> = $('#collapse_button');

        if (collapse && collapse.children().length !== 0) {
            collapseButton.addClass('visible');
            collapseButton.on('click', (e) => {
                e.preventDefault();
                collapseButton.toggleClass('expanded');
                collapse.children(':not(#collapse_button)').stop().slideToggle('fast');
            });
        }
    }
})();

// Mobile navigation
((): void => {
    const nav: JQuery<HTMLElement> = $('.m-nav');
    const navWrap: JQuery<HTMLElement> = $('.m-nav__wrap');
    let navOffsetTop = 0;
    let navScrollTop = 0;

    if (nav && navWrap) {
        $(window).on('scroll', () => {
            navOffsetTop = navWrap.offset()?.top ?? 0;
            navScrollTop = $(window).scrollTop() ?? 0;
            const state: boolean = navScrollTop > navScrollTop && navScrollTop > navOffsetTop;
            nav.toggleClass('mobile-hidden', state);
            nav.toggleClass('position-fixed', state);
        });
    }
})();

// Canvas
((): void => {
    const canvasWrapper: JQuery<HTMLElement> = $('.off-canvas-wrapper');
    const mobileMenu: JQuery<HTMLElement> = $('#m-menu');

    function closeNav(): void {
        if (mobileMenu.hasClass('is-open')) {
            mobileMenu.foundation('close');
        }
    }

    $(window).on('opened.zf.offcanvas', () => {
        canvasWrapper.addClass('off-canvas-wrapper_overlay');
        $('#oc-search').find('#id_query').trigger('focus');
    });

    $(window).on('close.zf.offcanvas', () => {
        canvasWrapper.removeClass('off-canvas-wrapper_overlay');
    });

    $('.js-off-canvas-overlay').on('swipeleft', closeNav);

    mobileMenu.on('swipeleft', closeNav);
})();
