import { ApiClient, apiClient } from '../../../api';

interface StopListResponse {
    is_banned: boolean
}

class UserStopListButton {
    private readonly elm: HTMLAnchorElement;
    private readonly url: string;
    private isBanned: boolean | undefined;
    private api: ApiClient;

    constructor(elm: HTMLAnchorElement, url: string) {
        this.elm = elm;
        this.url = url;

        if (!window.isAuthenticated) {
            this.elm.remove();

            return;
        }

        this.api = apiClient;

        this.elm.addEventListener('click', this.onClick);

        setTimeout(() => this.onLoad(), 300);
    }

    public static init(selectors = '[data-user-stoplist]'): void {
        document.querySelectorAll(selectors).forEach((value: HTMLAnchorElement) => {
            const userId = value.dataset.userStoplist;

            if (userId) {
                new UserStopListButton(value, `/user/${userId}/stoplist`);
            }
        });
    }

    private showAddToStoplistButton(): void {
        this.elm.setAttribute('title', 'Добавить пользователя в стоп-лист');
        this.elm.classList.remove('is-banned');
        this.isBanned = false;
    }

    private showRemoveFromStoplistButton(): void {
        this.elm.setAttribute('title', 'Удалить пользователя из стоп-листа');
        this.elm.classList.add('is-banned');
        this.isBanned = true;
    }

    private onLoad(): void {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.api.get<StopListResponse>(this.url).then(this.onSuccess, () => {});
    }

    private onClick = (e: Event): void => {
        e.preventDefault();

        if (this.isBanned) {
            this.api.delete<StopListResponse>(this.url).then(this.onSuccess, this.onError);
        } else {
            this.api.post<StopListResponse>(this.url).then(this.onSuccess, this.onError);
        }
    };

    private onSuccess = (response: { data: StopListResponse}): void => {
        this.isBanned = response.data.is_banned;
        this.isBanned ? this.showRemoveFromStoplistButton() : this.showAddToStoplistButton();
    };

    private onError = (): void => {
        alert('Произошла ошибка. Попробуйте повторить попытку позже.');
    };
}

export { UserStopListButton };
