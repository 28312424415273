import $ from 'jquery';
import Foundation from 'foundation-sites';
import Cookies from 'js-cookie';

const Announce = (breadcrumbsSelector: string, collapseSelector: string, cookieName = 'hide_announce'): void => {
    const $breadcrumbs: JQuery<HTMLElement> = $(breadcrumbsSelector);
    const $collapse: JQuery<HTMLElement> = $(collapseSelector);
    let isHide: string | undefined = Cookies.get(cookieName);

    if ($breadcrumbs) {
        if (isHide === 'yes') {
            $breadcrumbs.addClass('expanded');
        }

        $breadcrumbs.on('click', (e): boolean => {
            if (Foundation.MediaQuery.atLeast('medium')) {
                // клик именно на псевдоэлемент
                const width: number = $breadcrumbs.outerWidth(true) ?? 0;
                const clickX: number = (width - e.offsetX);

                if (clickX > 2 && clickX < 36) {
                    isHide = Cookies.get(cookieName);
                    if (isHide === 'yes') {
                        Cookies.remove(cookieName);
                    } else {
                        const today = new Date();
                        const expires = new Date();
                        expires.setFullYear(today.getFullYear());
                        expires.setMonth(today.getMonth());
                        expires.setDate(today.getDate());
                        expires.setUTCHours(23);
                        expires.setMinutes(59);
                        expires.setSeconds(59);
                        Cookies.set(cookieName, 'yes', { path: '/', expires: expires });
                    }
                    $breadcrumbs.toggleClass('expanded');
                    $collapse.stop().slideToggle('fast');
                    return false;
                }
            }

            return true;
        });

        $breadcrumbs.addClass('visible');
    }
};

export { Announce };
