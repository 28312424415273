import Foundation from 'foundation-sites';

class BackToTop {
    private button: HTMLElement | undefined;
    private buttonHidden = true;

    constructor() {
        window.addEventListener('scroll', this.onScroll);
    }

    public static init(): BackToTop {
        return new BackToTop();
    }

    private showButton(): void {
        if (!this.button) {
            this.button = document.createElement('div');
            this.button.classList.add('icon', 'icon-gotop', 'short-delay', 'bounce-in-out');
            this.button.addEventListener('click', this.onClickButton);
            document.body.append(this.button);
        }

        Foundation.Motion.animateIn(this.button, 'slide-in-up', () => {});
        // $(this.button).fadeIn(300);
        this.buttonHidden = false;
    }

    private hideButton(): void {
        if (this.button) {
            Foundation.Motion.animateOut(this.button, 'slide-out-down', () => {});
            // $(this.button).fadeOut(600);
        }
        this.buttonHidden = true;
    }

    private onScroll = (): void => {
        if (window.scrollY > window.innerHeight) {
            if (this.buttonHidden) {
                this.showButton();
            }
        } else if (!this.buttonHidden) {
            this.hideButton();
        }
    };

    private onClickButton = (e: Event): void => {
        e.preventDefault();
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        } catch (err) {
            if (err instanceof TypeError) {
                window.scroll(0, 0);
            }
        }
    };
}

export { BackToTop };
