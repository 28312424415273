export const copyLink = (selector: string): void => {
    $(selector).on('click', function (event) {
        event.preventDefault();

        $('.copy_value').trigger('focus').trigger('select');

        document.execCommand('copy');

        $(this).val('Скопировано').prop('disabled', true);
    });
};
