const cpMenuId = 'cpmenu';
const cpMenuArrowId = 'cpmenu_arrow';
const cpMenuPanelId = 'cpmenu_panel';

class ControlPanelMenu {
    private readonly cpMenu: HTMLElement;
    private readonly cpMenuPanel: HTMLElement;
    private readonly cpMenuArrow: HTMLElement;
    private cpMenuTimer: NodeJS.Timeout;

    constructor(cpMenu: HTMLElement, cpPanel: HTMLElement, cpArrow: HTMLElement) {
        this.cpMenu = cpMenu;
        this.cpMenu.addEventListener('mouseenter', this.onMouseEnter);
        this.cpMenu.addEventListener('mouseleave', this.onMouseLeave);

        this.cpMenuArrow = cpArrow;
        this.cpMenuArrow.addEventListener('click', this.onClick);

        this.cpMenuPanel = cpPanel;
    }

    public static init(): ControlPanelMenu | void {
        const cpMenu = document.getElementById(cpMenuId);
        const cpArrow = document.getElementById(cpMenuPanelId);
        const cpPanel = document.getElementById(cpMenuArrowId);
        if (cpMenu && cpArrow && cpPanel) {
            return new ControlPanelMenu(cpMenu, cpArrow, cpPanel);
        }
    }

    private isHidden = (): boolean => {
        return this.cpMenuPanel.style.display !== 'block';
    };

    private hide = (): void => {
        this.cpMenu.classList.remove('hover');
        this.cpMenuPanel.style.display = 'none';
    };

    private show = (): void => {
        this.cpMenu.classList.add('hover');
        this.cpMenuPanel.style.display = 'block';
    };

    private onMouseEnter = (): void => {
        if (this.cpMenuTimer) {
            clearTimeout(this.cpMenuTimer);
        }
        if (this.isHidden()) {
            this.show();
        }
    };

    private onMouseLeave = (): void => {
        this.cpMenuTimer = setTimeout(this.hide, 350);
    };

    private onClick = (): void => {
        // e.preventDefault();
        this.isHidden() ? this.show() : this.hide();
    };
}

export { ControlPanelMenu };
