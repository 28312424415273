import $ from 'jquery';

import Cookies from 'js-cookie';

let csrfToken: string | undefined;

const csrfSafeMethod = (method: string): boolean => {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
};

$.ajaxSetup({
    beforeSend: (jqXHR, settings) => {
        if (csrfToken === undefined) {
            csrfToken = Cookies.get('csrftoken');
        }
        if (!csrfSafeMethod(settings.type!) && settings.crossDomain && csrfToken !== undefined) {
            jqXHR.setRequestHeader('X-CSRFToken', csrfToken);
        }
    }
});
