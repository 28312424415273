import './styles.scss';
import { MagazineSubscribeMutation, MagazineSubscribeMutationVariables, apolloClient } from '../../../graphql';
import { MAGAZINE_SUBSCRIBE } from './mutations';

interface MagazineSubscriptionFormElement extends HTMLFormElement {
    fio: HTMLInputElement;
    email: HTMLInputElement;
}

const resetCustomValidity = (ev: Event) => {
    const target = ev.target as HTMLInputElement;

    target.setCustomValidity('');
};

export const magazineSubscribeForm = (elm: MagazineSubscriptionFormElement): void => {
    elm.fio.oninput = resetCustomValidity;
    elm.email.oninput = resetCustomValidity;

    elm.onsubmit = async (e) => {
        e.preventDefault();

        await apolloClient.mutate<MagazineSubscribeMutation, MagazineSubscribeMutationVariables>({
            mutation: MAGAZINE_SUBSCRIBE,
            variables: {
                email: elm.email.value,
                name: elm.fio.value,
            },
            update: (_, { data }) => {
                if (data?.subscriptionCreate?.subscription?.isSubscribed) {
                    const divSuccess = document.createElement('div');

                    divSuccess.setAttribute('class', 'message');
                    divSuccess.textContent = elm.fio.value + ', подписка успешно оформлена!';
                    elm.replaceWith(divSuccess);

                } else if (data?.subscriptionCreate?.errors) {
                    data.subscriptionCreate.errors.map((error) => {
                        if (error.field === 'email') {
                            elm.email.setCustomValidity(error.message ?? 'Ошибка');
                            elm.email.reportValidity();
                        }
                        if (error.field === 'name') {
                            elm.fio.setCustomValidity(error.message ?? 'Ошибка');
                            elm.fio.reportValidity();
                        }
                    });
                }
            }
        });
    };
};

export default magazineSubscribeForm;
