const DEFAULT_REFRESH_INTERVAL = 30 * 1000; // ms

class UserOnlineStatusStatic {
    protected timer: NodeJS.Timeout;
    protected ws: WebSocket;

    constructor() {
        const basePart: string = (location.protocol === "https:" ? "wss://" : "ws://") + location.hostname + (location.port ? ":" + location.port : "");
        const wssSource = "/ws/user/online";

        this.ws = new WebSocket(basePart + wssSource);
        this.ws.onopen = this.startTimer;
        this.ws.onclose = this.resetTimer;
        this.ws.onerror = this.resetTimer;
        this.ws.onmessage = this.refreshTimer;
    }

    public static init(): UserOnlineStatusStatic | void {
        if (window.isAuthenticated) {
            return new UserOnlineStatusStatic();
        }
    }

    private startTimer = (): void => {
        this.timer = setTimeout(this.refreshTimer, DEFAULT_REFRESH_INTERVAL);
    }

    public refreshTimer = (): void => {
        this.ws.send("ping");
        this.timer = setTimeout(this.refreshTimer, DEFAULT_REFRESH_INTERVAL);
    };

    private resetTimer = (): void => {
        clearTimeout(this.timer);
    }
}

type UserOnlineStatus = UserOnlineStatusStatic;
const UserOnlineStatus = UserOnlineStatusStatic as typeof UserOnlineStatusStatic & (() => UserOnlineStatus);

export { UserOnlineStatus };
